<template>
  <v-container>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-card>
      <v-card-title class="justify-space-between">
        <v-row>
          <v-col cols="6">
            Edit An {{employerStore.fieldLabelSettings.groupLabel}}
          </v-col>
          <v-col cols="6" class="d-flex justify-end">
            <v-btn v-if="employerBillingSettings.billingMethod && employerBillingSettings.invoiceCollection != 'remittance'" @click="$router.push({ name: 'billing', query: { employerID: fullEmployerObject.employer.employerID } })" class="ml-3 white--text" color="success">
            <span>Billing Information</span>
          </v-btn>
          <v-btn   @click="manageTemplate = !manageTemplate"  class="ml-3 white--text" color="success">
            <span>Manage Upload Template</span>
          </v-btn>
        </v-col>
      </v-row>
        
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-form v-model="emloyeeIsValid" ref="employeeListForm" lazy-validation>
              <v-row>
                <v-col cols="9">
                  <v-autocomplete :label=" employerList.length > 0 ? 'To re-enable '+employerStore.fieldLabelSettings.groupLabel+' selection please click Change '+employerStore.fieldLabelSettings.groupLabel : 'Select Parent ' + employerStore.fieldLabelSettings.groupLabel" v-model.trim="selectedEmployer" 
                    :items="employerList" :item-text="(item) => item.name" :item-value="(item) => item.employerID" :disabled="selectedEmployer != ''" :rules="[(v) => !!v || employerStore.fieldLabelSettings.groupLabel + ' is required']" clearable required></v-autocomplete>
                </v-col>
                <v-col cols="3">
                  <v-btn v-if="selectedEmployer" @click="clearEmployee" color="cancel" class="ma-2" dark>
                    Change {{employerStore.fieldLabelSettings.groupLabel}}
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <p class="font-weight-bold primary--text mt-3">
              {{employerStore.fieldLabelSettings.groupLabel}} Demographics:
            </p>
          </v-col>
          <v-col cols="12" class="ml-2">
            <v-form v-model="valid" ref="form" lazy-validation>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field v-model.trim="employer.name" v-maska="{ mask: 'S*', tokens: { S: { pattern: /[0-9a-zA-Z-.£,'&()%# ]/ } } }" :rules="[(v) => !!v || 'Name is required']" label="Name" required></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field v-model.trim="employer.externalId" label="External ID" required hint="This is the unique identifer your system uses"></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="4">
                  <v-text-field v-model.trim="employer.email"
                    :rules="[ rules.emailValidation ]"
                    label="Email">
                  </v-text-field>
                </v-col>
                
                <v-col cols="12" md="4">
                  <label>Primary Phone</label>
                  <vue-tel-input v-model.trim="employer.phonePrimary" @input="onPhonePrimaryObject" />
                  <div v-if="employer.phonePrimary">
                      <small v-if="!phonePrimaryObject.valid" class="error--text">Primary Phone number is not valid</small>
                  </div>
                </v-col>

                <v-col cols="12" md="4">
                  <label>Secondary Phone</label>
                  <vue-tel-input v-model.trim="employer.phoneSecondary" @input="onPhoneSecondaryObject" />
                  <div v-if="employer.phoneSecondary">
                    <small v-if="!phoneSecondaryObject.valid" class="error--text">Secondary Phone number is not valid</small>
                  </div>
                </v-col>
              </v-row>
              
              <v-row>
                <v-col cols="12" md="5">
                  <v-select v-model.trim="employer.categoryID" :items="employerType" :item-text="(item) => item.name" :item-value="(item) => item.lookupID" :rules="[(v) => !!v || employerStore.fieldLabelSettings.groupLabel + ' Type is required']" :label="employerStore.fieldLabelSettings.groupLabel + ' Type'" required></v-select>
                </v-col>
                <v-col cols="12" md="5" v-if="isReviveAdmin">
                  <v-select v-model.trim="employer.customerType" :items="customerTypes" :item-text="(item) => item.text" :item-value="(item) => item.value" label="Customer Source"></v-select>
                </v-col>
                <v-col cols="12" md="2" light color="primary" v-if="isReviveAdmin">
                  <v-switch v-model="employer.hasCopay" label="Has Copay?"></v-switch>
                </v-col>
                <v-col cols="12" md="6" v-if="isReviveAdmin">
                  <v-text-field v-model.trim="employer.hubspotId" label="Hubspot ID" :readonly="initialHubspotID != null"></v-text-field>
                </v-col>
                <v-col cols="12" md="6" >
                  <v-text-field :append-icon="isReviveAdmin ? (marker ? 'mdi-pencil' : 'mdi-close-circle') : ''" v-maska="{ mask: '#*', tokens: { '#': { pattern: /[0-9]/ } } }"
                      v-model.trim="employer.groupID" :counter="10" :rules="groupIDRules" :readonly="marker" @click:append="marker = !marker" label="Group ID" ></v-text-field>
                </v-col>
              </v-row>
              
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field v-model.trim="employer.primaryStreet" v-maska="{ mask: 'S*', tokens: { S: { pattern: /[a-zA-Z-.£,'&()/%0-9# ]/ } } }" :rules="[(v) => !!v || 'Address is required']" label="Address Line 1" required></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    v-model.trim="employer.primaryStreetTwo"
                    v-maska="{
                      mask: 'S*',
                      tokens: { S: { pattern: /[a-zA-Z-.£,'&()/%0-9# ]/ } },
                    }"
                    label="Address Line 2"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field v-model.trim="employer.primaryCity" v-maska="{ mask: 'S*', tokens: { S: { pattern: /[a-zA-Z-.£,'&()% ]/ } } }" :rules="[(v) => !!v || 'City is required']" label="City" required></v-text-field>
                </v-col>
              </v-row>
              <v-row class="mb-5">
                <v-col cols="12" md="8">
                  <v-select v-model.trim="employer.primaryRegion" :items="states" :item-text="(item) => item.name" :rules="[(v) => !!v || 'Region is required']" :item-value="(item) => item.abbreviation" label="Select Region"></v-select>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field v-model.trim="employer.primaryPostal"
                    :rules="[
                      rules.zipCodeValidation(employer.primaryPostal)
                    ]"
                    v-maska="{
                      mask: '#*',
                      tokens: { '#': { pattern: /[0-9\-]/ } },
                    }"
                    maxlength="10"
                    label="Postal Code"></v-text-field>
                </v-col>
                <v-col cols="12" class="ml-2">
                  <v-expansion-panels v-model.trim="secondaryExpansionPanel">
                    <v-expansion-panel>
                      <v-expansion-panel-header>
                        <span class="font-weight-bold primary--text mt-3">Shipping Address</span>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content eager>
                          <v-row>
                            <v-col cols="4">
                              <v-switch label="Use The Billing Address" v-model.trim="useBillingAddress" @change="billingAddressToggle($event)"></v-switch>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="12" md="6">
                              <v-text-field v-model.trim="employer.secondaryStreet" v-maska="{ mask: 'S*', tokens: { S: { pattern: /[a-zA-Z-.£,'&()/%0-9# ]/ } } }" label="Address Line 1" :disabled="useBillingAddress" :rules="[(v) => !!v || 'Shipping address is required']" required></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                              <v-text-field v-model.trim="employer.secondaryStreetTwo" v-maska="{ mask: 'S*', tokens: { S: { pattern: /[a-zA-Z-.£,'&()/%0-9# ]/ } } }" label="Address Line 2" :disabled="useBillingAddress"></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="12" md="6">
                              <v-text-field v-model.trim="employer.secondaryCity" v-maska="{ mask: 'S*', tokens: { S: { pattern: /[a-zA-Z-.£,'&()% ]/ } } }" label="City" :disabled="useBillingAddress" :rules="[(v) => !!v || 'Shipping city is required']" required></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row class="mb-5">
                            <v-col cols="12" md="8">
                              <v-select v-model.trim="employer.secondaryRegion" :items="states" :item-text="(item) => item.name" :item-value="(item) => item.abbreviation" label="Select Region" :disabled="useBillingAddress" :rules="[(v) => !!v || 'Shipping region is required']" required></v-select>
                            </v-col>
                            <v-col cols="12" md="4">
                              <v-text-field v-model.trim="employer.secondaryPostal"
                              :rules="[
                                rules.zipCodeValidationPostal(employer.secondaryPostal)
                              ]"
                              v-maska="{
                                mask: '#*',
                                tokens: { '#': { pattern: /[0-9\-]/ } },
                              }"
                              maxlength="10"
                              :disabled="useBillingAddress" required></v-text-field>
                            </v-col>
                          </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
          <v-col cols="12" class="ml-2" id="contractUpload">
            <v-expansion-panels v-model.trim="expandUploadPanel">
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <span class="font-weight-bold primary--text mt-3">
                    Document Uploads
                  </span>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-row>
                        <v-col cols="10">
                          <v-file-input v-model.trim="employer.documentURL" accept=".pdf" placeholder="Upload the contract Document" label="Contract Document" prepend-icon="mdi-paperclip">
                            <template v-if="employer.documentURL != oldDocumentURL" v-slot:selection="{ text }">
                              <v-chip small label color="primary">{{ text }}</v-chip>
                            </template>
                          </v-file-input>
                        </v-col>
                        <v-col cols="2" v-if="oldDocumentURL">
                          <v-btn color="success" class="ma-2" :disabled="!oldDocumentURL" @click="downloadFile">Download Current<v-icon class="ma-2">download</v-icon></v-btn>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
          <v-col cols="12" class="ml-2">
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <span class="font-weight-bold primary--text mt-3">
                    {{employerStore.fieldLabelSettings.secondaryServiceLabel}}: {{ employerProductList.length }} Added
                  </span>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-item-group v-model.trim="addProductList">
                    <v-row>
                        <v-col md="3" v-for="product in filteredProductList" :key="product.skuid" class="m-2">
                          <product-list :isDefault="product.isDefault" :skuid="product.skuid" :name="product.name" :description="product.description" :defaultPrice="product.defaultPrice" :isAdmin="false"></product-list>
                        </v-col>
                        <!-- for admin -->
                        <v-col md="3" v-for="product in filteredAdminProductList" :key="product.skuid" class="m-2">
                          <product-list :isDefault="product.isDefault" :skuid="product.skuid" :name="product.name" :description="product.description" :defaultPrice="product.defaultPrice" :isAdmin="true"></product-list>
                        </v-col>
                    </v-row>
                  </v-item-group>

                  <v-row v-if="filteredProductList.length || filteredAdminProductList.length">
                    <v-col>
                      <v-btn color="success" class="ma-2" :disabled="addProductList.length < 1" @click="addProducts">Add</v-btn>
                    </v-col>
                  </v-row>

                  <v-row v-if="filteredProductList.length || filteredAdminProductList.length">
                    <v-col cols="12">
                      <p class="font-weight-bold primary--text mt-5">
                        {{employerStore.fieldLabelSettings.secondaryServiceLabel}} to Add:
                      </p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-data-table :headers="employerProductHeaders" :items="employerProductList" hide-default-footer>
                        <template v-slot:[`item.actions`]="{ item }">
                          <v-icon small class="mr-2" @click="removeEmployerProduct(item)">delete</v-icon>
                        </template>
                        <template v-slot:[`item.isDefault`]="{ item }">
                          <v-switch v-model="item.isDefault" @change="handleChange($event, item.skuid, item.isDefault)"></v-switch>
                        </template>
                        <template v-slot:no-data>
                          No {{employerStore.fieldLabelSettings.serviceLabel}} added. Please add a {{employerStore.fieldLabelSettings.serviceLabel}} to this {{employerStore.fieldLabelSettings.groupLabel}}.
                        </template>
                        <!-- <v-col cols="4">
                          <v-switch label="Use The Billing Address" v-model.trim="useBillingAddress" @change="billingAddressToggle($event)"></v-switch>
                        </v-col> -->
                      </v-data-table>
                    </v-col>
                  </v-row> 
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
          <v-col cols="12" class="ml-2" v-if="isReviveAdmin && filteredProductsWithVisitLimit.length">
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <span class="font-weight-bold primary--text mt-3">
                    {{employerStore.fieldLabelSettings.secondaryServiceLabel}} Visit Limits
                  </span>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-card>
                    <v-list flat>
                      <v-list-item-group>
                        <v-list-item v-for="(product, i) in filteredProductsWithVisitLimit" :key="i">
                            <v-list-item-content>
                              <v-list-item-title>{{ product.name }}</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-text-field class="pe-4" v-maska="{ mask: '#*', tokens: { '#': { pattern: /[0-9]/ } },}" v-model="product.visitLimit" label="Visit Limit"></v-text-field>
                            </v-list-item-action>
                            <v-list-item-action>
                              <v-switch color="primary" v-model="product.enableFeeForService">
                                <template v-slot:label>
                                  <div class="ml-2">
                                    Enable Fee for Visit Limit?
                                  </div>
                                </template>
                              </v-switch>
                            </v-list-item-action>
                            <v-list-item-action>
                              <v-text-field v-model="product.serviceFee" v-maska="{ mask: '#*', tokens: { '#': { pattern: /[0-9\.]/ } },}" :disabled="!product.enableFeeForService" label="Fee for Visit Limit"></v-text-field>
                            </v-list-item-action>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-card>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
          <v-col cols="12" class="ml-2">
            <employer-settings @employerSettings="handleSettings" :isSubmitted="isSubmit" :memberObj="memberSettings" 
                        :billingObj="billingSettings" :systemObj="systemSettings" :fieldLabelObj="fieldLabel" 
                        :customizationObj="customization" :discount="discountDetails" :isAdmin="isReviveAdmin" 
                        :parentAttributes="parentEmployerAttribute" :userDetails="chargifyUserDetails" :employerProducts="employerProductList"
                         :employerID="employerID" :customerType="employer.customerType"></employer-settings>
          </v-col>
          <v-col cols="12" class="d-flex justify-center">
            <v-btn color="success" class="ma-2" @click="saveEmployer">Save</v-btn>
            <v-btn color="cancel" dark class="ma-2" @click="$router.push({ name: 'group_admin_index' })">Cancel</v-btn>
          </v-col>
        </v-row>
        <v-dialog width="90%" v-if="manageTemplate" v-model.trim="manageTemplate">
          <upload-settings @cancel="manageTemplate = !manageTemplate"></upload-settings>
        </v-dialog>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import states from "../../../state.json";
import { useAuthStore } from "../../stores/auth";
import { useChargifyStore } from "../../stores/chargify";
import { roles } from "../../data/definitions/roles";
import { useLookupStore } from "../../stores/lookup";
import { useProductStore } from "../../stores/product";
import ProductList from "../ProductListComponent.vue";
import EmployerSettings from "./SettingsComponent.vue";
import UploadSettings from "./UploadSettings.vue";
import { useEmployerStore } from "../../stores/employer";
import { lookupTypes } from "../../data/definitions/lookupTypes";
import {
  convertToEmployerObject,
  convertToSettingsPayload,
  handleRedirectAfterEditingEmployer,
  downloadPDF,
  customerTypes,
  employerPricingStructureTypes,
  convertToAssignLimitType,
  convertToCustomerAttribute
} from "../../commons/employer";
import { getInstance } from "../../auth";
import { isValidEmail } from "../../commons/formValidators";

export default {
  name: "NewEmployerComponent",
  components: {
    EmployerSettings,
    ProductList,
    UploadSettings
  },
  setup() {
    const employerStore = useEmployerStore();
    const productStore = useProductStore();
    const lookupStore = useLookupStore();
    const authStore = useAuthStore();
    const authService = getInstance();
    const chargifyStore = useChargifyStore();

    return { employerStore, productStore, lookupStore, chargifyStore, authStore, states, authService, customerTypes };
  },
  data() {
    return {
      manageTemplate: false,
      expandUploadPanel: 1,
      overlay: false,
      memberSettings: {},
      systemSettings: {},
      billingSettings: {},
      fieldLabel: {},
      customization: {},
      discountDetails: {},
      phonePrimaryObject: { valid: false },
      phoneSecondaryObject: { valid: false },
      isSubmit: false,
      validPhoneInput: true,
      valid: true,
      emloyeeIsValid: true,
      secondaryExpansionPanel: 0,
      primaryExpansionPanel: 0,
      rules: {
        zipCodeValidation(zipCode){
          if(/^\d{5}?(([-]|\s*)(\d{4}))?$/.test(zipCode))
            return true;
          return "Postal code must be valid"
        },
        zipCodeValidationPostal(zipCode){
          if(/^\d{5}?(([-]|\s*)(\d{4}))?$/.test(zipCode))
            return true;
          return "Shipping Postal code must be valid";
        },
        emailValidation: [
          (emailAddress) => !emailAddress || isValidEmail(emailAddress) || "E-mail must be valid"
        ],
      },
      groupIDRules: [(v) => !!v || "Group ID is required", (v) => /^[0-9]{0,10}$/.test(v) || "Group ID must not exceed 10 characters"],
      selected: false,
      selectedEmployer: "",
      selectedEmployerDetails: {},
      parentEmployerID: null,
      useBillingAddress: false,
      employerBillingSettings: {},
      singleEmployer: {},
      initialHubspotID: null,
      chargifyUserDetails: {},
      marker: true,
      employer: {
        name: "",
        externalId: "",
        hubspotId: "",
        phonePrimary: "",
        phoneSecondary: "",
        primaryStreet: "",
        primaryStreetTwo: "",
        primaryCity: "",
        primaryRegion: "",
        primaryPostal: "",
        primaryCountry: "US",
        secondaryStreet: "",
        secondaryStreetTwo: "",
        secondaryCity: "",
        secondaryRegion: "",
        secondaryPostal: "",
        secondaryCountry: "US",
        categoryID: "",
        documentURL: null,
        groupID: "218347",
        customerType: "",
        hasCopay: false,
        email: ""
      },
      parentEmployerAttribute: [],
      oldDocumentURL: "",
      fullEmployerObject: [],
      productList: [],
      parentProductList: [],
      addProductList: [],
      employerProductList: [],
      employerProductHeaders: [ { text: "Name", value: "name" }, { text: "Description", value: "description" }, { text: "Default", value: "isDefault" }, { text: "Remove", value: "actions", sortable: false } ],
      allProductStock: [],
      isReviveAdmin: false,
      removedProducts: [],
      isDirty: false,
      pageLoad: true,
      showSwal: true,
      dontWarnDocument: false,
      employerList: [],
      productsWithVisitLimit: [],
      filteredProductsWithVisitLimit: [],
      employerID: this.$route.query.employerID,
    };
  },
  computed: {
    employerType: {
      get() { return this.lookupStore.employerType; }
    },
    filteredProductList: {
      get() {
        if (this.employerProductList.length > 0) {
          let newList = [];
          //find products already added to employer and remove from available list.
          this.parentProductList.forEach((product) => {
            const found = this.employerProductList.find((x) => x.skuid == product.skuid);
            if (!found) { newList.push(product); }
          });

          return newList;
        }
      
        return this.parentProductList;
      },
    },
    filteredAdminProductList: {
      get() {
        let filteredList = [];
        this.allProductStock.forEach((product) => {
          const found = this.parentProductList.find( (x) => x.skuid == product.skuid );
          const foundProduct = this.productList.find( (x) => x.skuid == product.skuid );
          if (!found && !foundProduct) {
            filteredList.push(product);
          }
        });
        if (this.employerProductList.length > 0) {
          let newList = [];
          filteredList.forEach((product) => {
            const found = this.employerProductList.find( (x) => x.skuid == product.skuid );
            if (!found) {
              product.isDefault = false;
              newList.push(product); 
            }
          });
          return newList;
        }
        else{ return filteredList; }
      },
    },
  },
  methods: {
    filteredProductVisitLimits(filteredProductList) {
      if (this.productsWithVisitLimit.length && filteredProductList.length) {
        let productList = this.productsWithVisitLimit.filter(product => filteredProductList.some(x => x.skuid === product.skuid));
        
        this.filteredProductsWithVisitLimit = productList.filter((product, index, array) => array.findIndex(item => item.name === product.name) === index);

        return;
      }
      this.filteredProductsWithVisitLimit = [];
    },
    handleChange(e,id, value){
      this.employerProductList.forEach( (ep) => {
        if(ep.skuid.toLowerCase() === id.toLowerCase())
          ep.isDefault = value;
        else
          ep.isDefault = false;
      });

      let checkForDefaultProduct = this.employerProductList.find(x => x.isDefault)
      
      if (!checkForDefaultProduct) {
        return this.$swal({
          title: "Error",
          icon: "error",
          text: "Default product is not selected.",
          confirmButtonColor: this.$vuetify.theme.themes.light.success,
        });
      }
    },
    downloadFile(){
      try {
        let docURL = "/uploads" +this.oldDocumentURL.toLowerCase().split("uploads")[1];
        downloadPDF(docURL, this.employerStore.fieldLabelSettings.groupLabel + '-contract.pdf');
      } catch (error) {
        return this.$swal({
            title: 'Error',
            text: 'An error occurred: ' + error,
            icon: 'error',
            confirmButtonText: 'Try Again',
            confirmButtonColor: this.$vuetify.theme.themes.light.success
          });
      }
    },
    preventReload(event) {
      /* the beforeunload event functions based on the value of isDirty. 
       if false, event would run but if true, user would receive a prompt message when reloading page */
      if (!this.isDirty) return
      event.preventDefault();
      event.returnValue = "";
    },
    getProductStockByEmployer() {
      if (this.selectedEmployer) {
        this.productStore
          .getProductStockByEmployer(this.selectedEmployer)
          .then((res) => {
            this.parentProductList = res.data ?? [];
            return;
          });
      }
      this.parentProductList = [];
      this.productList = [];
    },
    addProducts() {
      const found = this.parentProductList.some(x => x.skuid == this.addProductList.skuid);
      if (!found && !this.isReviveAdmin) {
        this.$swal({
          title: "Error",
          icon: "error",
          text: "You cannot add this "+this.employerStore.fieldLabelSettings.serviceLabel+" because you don't have the Revive admin role",
          confirmButtonColor: this.$vuetify.theme.themes.light.success,
        });
        return;
      }
      if(this.removedProducts.includes(this.addProductList.skuid)){
        this.removedProducts = [ ...this.removedProducts.filter((x) => x != this.addProductList.skuid) ];
      }
      this.employerProductList.push(this.addProductList);
      this.addProductList = [];
    },
    removeEmployerProduct(item) {
      const existingProduct = this.fullEmployerObject.employerSKU.find( (x) => x.skuid == item.skuid );
      if(existingProduct){ this.removedProducts.push(item.skuid); }
      this.employerProductList = [ ...this.employerProductList.filter((x) => x != item) ];
    },
    onPhonePrimaryObject(formattedNumber, phoneObject) {
      this.phonePrimaryObject = phoneObject;
    },
    onPhoneSecondaryObject(formattedNumber, phoneObject) {
      this.phoneSecondaryObject = phoneObject;
    },
    clearEmployee() {
      this.$swal({
        title: "Warning",
        icon: "warning",
        text: "Warning, this will clear any "+this.employerStore.fieldLabelSettings.serviceLabel+" you have added to this "+this.employerStore.fieldLabelSettings.groupLabel+", are you sure you wish to continue.",
        buttons: true,
        showDenyButton: true,
        confirmButtonColor: this.$vuetify.theme.themes.light.success,
        denyButtonColor: this.$vuetify.theme.themes.light.deny,
        confirmButtonText: "Continue",
        denyButtonText: "Cancel",
      }).then((result) => {
        if (result.isConfirmed) {
          this.productList = [];
          this.addProductList = [];
          this.employerProductList = [];
          this.selectedEmployer = "";
        }
      });
    },
    getProductsListing() {
      this.productStore.getProductStockByEmployer(this.$route.query.employerID).then((res) => {
          this.productList = res.data ?? [];
          //parse products.
          if (this.fullEmployerObject.employerSKU.length > 0) {
            this.fullEmployerObject.employerSKU.forEach((product) => {
              const find = this.productList.find( (x) => x.skuid == product.skuid );
              if(find){ 
                const employerProduct = { 
                  skuid: product.skuid, 
                  name: find.name, 
                  isDefault: find.isDefault ? find.isDefault : false, 
                  description: find.description, 
                  defaultPrice: product.overridePrice 
                };
                employerProduct.settings = { enableApps: null, employerSKUID: product.employerSKUID, selectedApps: [] };

                const empSkuEnableAppsSetting = product.attributes?.find(
                  (x) => x.attributeType == lookupTypes.settings.enableApps
                );
                if (empSkuEnableAppsSetting) {
                  if(empSkuEnableAppsSetting.attributeValue != null && empSkuEnableAppsSetting.attributeValue.trim() == '')
                    employerProduct.settings.enableApps = null;
                  else
                    employerProduct.settings.enableApps = empSkuEnableAppsSetting.attributeValue;
                  employerProduct.settings.attributeID = empSkuEnableAppsSetting.attributeID;
                }
                if(product.partners?.length > 0) {
                  employerProduct.settings.selectedApps = product.partners.map((x) => x.partnerID);
                }
                else if(this.fullEmployerObject.employer.customerType == 'manifest' && !empSkuEnableAppsSetting){
                  employerProduct.settings.enableApps = 'No';
                }
                else if(!empSkuEnableAppsSetting){
                  employerProduct.settings.enableApps = 'Yes';
                  employerProduct.settings.selectedApps.push(lookupTypes.partners.reviveApp);
                }
                this.employerProductList.push(employerProduct);
              }
            });
          }
          return;
        });
      this.productList = [];
    },
    successResponse(payload) {
      if(this.showSwal) {
        return this.$swal({
            title: "Saved",
            icon: "success",
            confirmButtonColor: this.$vuetify.theme.themes.light.success,
            denyButtonColor: this.$vuetify.theme.themes.light.deny,
            confirmButtonText: "Continue",
            buttons: true,
          }).then(() => {
            this.$router.push(handleRedirectAfterEditingEmployer(payload.billing.billingMethod, this.fullEmployerObject.employer.billingCustomerID, this.fullEmployerObject.employer.employerID));
          });
      }else {
        this.showSwal = true;
        this.$router.push(handleRedirectAfterEditingEmployer(payload.billing.billingMethod, this.fullEmployerObject.employer.billingCustomerID, this.fullEmployerObject.employer.employerID));
      }
    },
    async handleSettings(payload) {
      this.isSubmit = false;

      let phoneValidation = !this.phonePrimaryObject.valid
      ? "- Primary Phone Number is Required"
      : "";
      if (phoneValidation) {
        this.employer.phonePrimary = "+1";
      }
      
      let secondaryPhoneValidation = this.employer.phoneSecondary && !this.phoneSecondaryObject.valid ? "<br/> - Secondary Phone Number is Invalid" : "";
      
      let displayError = (validation) => {
        
        let formValidate = validation == this.$refs.form._data.inputs ? true : false;
        let phoneNum = formValidate == true ? phoneValidation : "";
        let settingsFormObject = payload.valid == true ? "" : payload.validationMessages;
        // if(!payload.phoneObject.valid && payload.customization.phoneNumber)
        let settingsPhoneNumValidation = !payload.phoneObject.valid && payload.customization.phoneNumber ? "<br/> - Customization Phone Number is Invalid" : "";

        let newValidationObject;
        if(settingsFormObject) {
          newValidationObject = Object.assign(settingsFormObject, validation);
        }else {
          newValidationObject = validation;
        }

        this.$swal({
          title: "Error",
          icon: "error",
          html: (
            phoneNum + secondaryPhoneValidation + settingsPhoneNumValidation + 
            Object.keys(newValidationObject).map((key) => {
              if (newValidationObject[key].validations) {
                if (newValidationObject[key].validations.length > 0) {
                  return "<br>" + "- " + newValidationObject[key].validations[0];
                }
              }
            })
          ).replace(/,/g, ""),
          confirmButtonColor: this.$vuetify.theme.themes.light.success,
          denyButtonColor: this.$vuetify.theme.themes.light.deny,
          confirmButtonText: "Try Again",
        });
      };
      
      if (!this.$refs.form.validate() || !this.phonePrimaryObject.valid || !payload.valid || !payload.phoneObject.valid && payload.customization.phoneNumber || this.employer.phoneSecondary && !this.phoneSecondaryObject.valid) {
        return displayError(this.$refs.form._data.inputs);
      }

      if (!this.employerProductList.length > 0) {
        this.$swal({
          title: "Error",
          icon: "error",
          text: "Choosing a " +this.employerStore.fieldLabelSettings.serviceLabel+ " is required to update this "+this.employerStore.fieldLabelSettings.groupLabel,
          confirmButtonColor: this.$vuetify.theme.themes.light.success,
        });
        return;
      }
      //if we made it this far check only secondary phone number again. 
      if (secondaryPhoneValidation.length > 1){
        
        this.$swal({
          title: "Error",
          icon: "error",
          html: secondaryPhoneValidation,
          confirmButtonColor: this.$vuetify.theme.themes.light.success,
          denyButtonColor: this.$vuetify.theme.themes.light.deny,
        });
        return;
      }

      if(!this.employer.documentURL && payload.billing.billingMethod == 'individualBilling' && !this.dontWarnDocument) {
        return this.$swal({
          title: "Warning",
          icon: "warning",
          text: `You haven't uploaded a contract. Please upload a contract before you can add ${this.employerStore.fieldLabelSettings.secondarySubscriberLabel}.`,
          buttons: true,
          showDenyButton: true,
          confirmButtonColor: this.$vuetify.theme.themes.light.success,
          denyButtonColor: this.$vuetify.theme.themes.light.deny,
          confirmButtonText: "Ok",
          denyButtonText: "Proceed Anyway",
        }).then((result) => {
          if (result.isConfirmed) {
            document.getElementById('contractUpload').scrollIntoView();
            this.expandUploadPanel = 0;
          }
          else {
            this.dontWarnDocument = true;
            this.isSubmit = true;
          }
        });
      }

      if(this.employer.documentURL && typeof this.employer.documentURL != 'string' && this.employer.documentURL?.type != 'application/pdf'){
        return this.$swal({
          title: "Error",
          icon: "error",
          html: "The document uploaded was not the correct type. Please upload a PDF and try again",
          confirmButtonColor: this.$vuetify.theme.themes.light.success,
          denyButtonColor: this.$vuetify.theme.themes.light.deny,
          confirmButtonText: "Try Again",
        });
      }

      let checkForDefaultProduct = this.employerProductList.find(x => x.isDefault)
      
      if (!checkForDefaultProduct) {
        return this.$swal({
          title: "Error",
          icon: "error",
          text: "Default product is not selected.",
          confirmButtonColor: this.$vuetify.theme.themes.light.success,
        });
      }

      let pricingRequest = null;

      //update employer pricing
      let employerBillingObj = JSON.parse(JSON.stringify(payload.billing.pricing));
      if(employerBillingObj != null && !employerBillingObj.isFromParent){
        if(employerBillingObj.pricingStructure === '1'){
          pricingRequest = {
            pricings: [...employerBillingObj.pricing],
            type: employerPricingStructureTypes.pmpm,
            pricingDetails: {
              hasFlatRate: employerBillingObj.hasFlatRate,
              flatRateFee: employerBillingObj.flatRateFee
            }            
          };
        }
        else if(employerBillingObj.pricingStructure === '2'){
          pricingRequest = {
            pricings: [...employerBillingObj.pricing],
            type: employerPricingStructureTypes.blended,
            pricingDetails: {
              hasFlatRate: employerBillingObj.hasFlatRate,
              flatRateFee: employerBillingObj.flatRateFee
            }
          };
        }
        else if(employerBillingObj.pricingStructure === '3'){
          if(employerBillingObj.tierStructure === '1'){
            pricingRequest = {
              pricings: [...employerBillingObj.pricing],
              type: employerPricingStructureTypes.tiered3,
              pricingDetails: {
                hasFlatRate: employerBillingObj.hasFlatRate,
                flatRateFee: employerBillingObj.flatRateFee
              }
            };
          }
          else if(employerBillingObj.tierStructure === '2'){
            pricingRequest = {
              pricings: [...employerBillingObj.pricing],
              type: employerPricingStructureTypes.tiered4,
              pricingDetails: {
                hasFlatRate: employerBillingObj.hasFlatRate,
                flatRateFee: employerBillingObj.flatRateFee
              }
            };
          }
        } 
      }

      //Set new billing payload
      let newBillPayload = {
        billingMethod: payload.billing?.billingMethod,
        enrolledInTheGroup: payload.billing?.enrolledInTheGroup,
        invoiceCollection: payload.billing?.invoiceCollection,
        netTerm: payload.billing?.netTerm
      };
      payload.billing = newBillPayload;

      if(newBillPayload.enrolledInTheGroup === false && (pricingRequest != null && pricingRequest.pricingDetails != null 
          && pricingRequest.pricingDetails.hasFlatRate == 1) ){
            this.$swal({
              title: "Warning",
              icon: "warning",
              text: 'You have entered a billing flat rate for the group, but "Can Enroll Members" is set to no. The Flat Rate fee will not be billed for this group.',
              buttons: true,
              showDenyButton: true,
              confirmButtonColor: this.$vuetify.theme.themes.light.success,
              denyButtonColor: this.$vuetify.theme.themes.light.deny,
              confirmButtonText: "Update Setting",
              denyButtonText: "Proceed Anyway",
            }).then((result) => {
                if (!result.isConfirmed) {
                  this.finalizeSavingEmployer(payload, pricingRequest);
                }
            });
      } else{
        this.finalizeSavingEmployer(payload, pricingRequest);
      }
    },

    finalizeSavingEmployer(payload, pricingRequest){
      //isDirty has to be set to false else the prompt message would show up when the route is about to change 
      this.isDirty = false;
      // set overlay to true
      this.overlay = true;
      //form submission to api
      let employer = convertToEmployerObject(this.employer.name, this.employer.externalId, this.employer.hubspotId, this.employer.phonePrimary, this.employer.phoneSecondary, this.employer.primaryStreet, this.employer.primaryStreetTwo, this.employer.primaryCity, this.employer.primaryRegion, this.employer.primaryPostal, 
                       this.employer.primaryCountry, this.employer.secondaryStreet, this.employer.secondaryStreetTwo, this.employer.secondaryCity, this.employer.secondaryRegion, this.employer.secondaryPostal, this.employer.secondaryCountry, this.employer.categoryID, this.selectedEmployer, this.employer.groupID, 
                       payload.discount.discountPercentage, payload.discount.numberOfMonths, this.employer.customerType, this.employer.hasCopay, this.employer.email);
                       
      employer.employerID = this.fullEmployerObject.employer.employerID;
      
      if(typeof this.employer.documentURL == 'string'){
        employer.documentURL = this.employer.documentURL;
      }

      if(pricingRequest != null){
        pricingRequest.employerID = employer.employerID;
      }

      this.employerStore.updateEmployer(employer).then((res) => {
        if(res?.employerID) {
          if(!payload.customization.enableCustomization){
            payload.customization.mainLogo = null;
            payload.customization.mobileLogo = null;

            if(payload.oldMainLogo){
              this.employerStore.deleteLogo(res.employerID, payload.oldMainLogo);
            }
            if(payload.oldMobileLogo){
              this.employerStore.deleteLogo(res.employerID, payload.oldMobileLogo);
            }
          }

          if(typeof payload.oldMainLogo === 'string' && typeof payload.customization.mainLogo === 'string' || payload.customization.mainLogo == null && payload.customization.enableCustomization){
            payload.customization.mainLogo = payload.oldMainLogo;
          }
          
          if(typeof payload.oldMobileLogo === 'string' && typeof payload.customization.mobileLogo === 'string' || payload.customization.mobileLogo == null && payload.customization.enableCustomization){
            payload.customization.mobileLogo = payload.oldMobileLogo;
          }

          let allPromises = [];

          if(pricingRequest != null){
            const pricingUpdate = {promise: this.employerStore.saveEmployerPricingStructure(pricingRequest), name: 'pricingSaveRequest'}
            allPromises.push(pricingUpdate);
          }

          let settingsArray = convertToSettingsPayload(res.employerID, payload, this.employer.hubspotId);

          if (settingsArray.length > 0) { 
            this.employerStore.assignSettings(res.employerID, settingsArray).then(async (settingsResult) => {

              if(settingsResult.status == 200){
                if(typeof payload.customization.mainLogo === 'object' && !Array.isArray(payload.customization.mainLogo) && payload.customization.mainLogo !== null && payload.customization.enableCustomization) {
                  
                  const upload = {promise: this.employerStore.uploadLogo(res.employerID, payload.customization.mainLogo, payload.customization, 'mainLogo', payload.oldMainLogo), name: 'uploadMainLogo'}
                  allPromises.push(upload);
                }

                if(this.employerProductList.length > 0){

                  //assign Products
                  this.employerProductList.forEach((prod) => {
                    let partners = [];
                    if(prod.settings?.selectedApps?.length > 0){
                      partners = prod.settings.selectedApps.map(partnerID => { return {partnerID: partnerID }; });
                    }
                    let addProduct = {promise: this.employerStore.assignProduct(res.employerID, prod.skuid, prod.defaultPrice, prod.isDefault, partners), name: `addedProduct${prod.skuid}`}; 
                    addProduct.promise.then((res) => {
                      if(prod.settings)  {
                        let skuSetting = {
                          attributeID: prod.settings.attributeID,
                          employerSKUID: res.employerSKUID,
                          attributeType: lookupTypes.settings.enableApps,
                          attributeValue: prod.settings.enableApps,
                          language: 'en-us'
                        };
                        if(skuSetting.attributeValue == null){
                          skuSetting.attributeValue = '';
                        }
                        const assignSkuSettingsPromise = {promise: this.employerStore.assignSkuSettings([skuSetting]), name: `addedProduct${prod.skuid}`};
                        allPromises.push(assignSkuSettingsPromise);
                      }
                    })
                    allPromises.push(addProduct);
                  })
                } 

                if(this.removedProducts.length > 0){
                  
                  //remove deleted products
                  this.removedProducts.forEach((id) => { 
                    const removedProduct = {promise: this.employerStore.removeProduct(res.employerID, id), name: `removedProduct${id}`}
                    allPromises.push(removedProduct);
                  })
                }

                if(typeof this.employer.documentURL === 'object' && !Array.isArray(this.employer.documentURL) && this.employer.documentURL !== null){

                  const contractUpload = {promise: this.employerStore.uploadContract(res.employerID, employer, this.employer.documentURL, this.oldDocumentURL), name: 'contractDocument'}
                  allPromises.push(contractUpload);
                }            

                if (this.employer.externalId) {

                  const assignID = {promise: this.employerStore.assignUniqueID(res.employerID, this.employer.externalId), name: 'assignUniqueID'}
                  allPromises.push(assignID);
                }

                if(this.filteredProductsWithVisitLimit.length > 0){
                  const assignVisit = {promise: this.employerStore.assignVisitLimits(res.employerID, convertToAssignLimitType(res.employerID, this.filteredProductsWithVisitLimit)), name: 'assignVisitLimits'}
                  allPromises.push(assignVisit);
                }
  
                if(payload.billing.invoiceCollection == 'remittance'){
                  let customer = convertToCustomerAttribute(payload.user.first_name, payload.user.last_name, payload.user.email, 
                  this.employer.secondaryPostal,  this.employer.phonePrimary, this.employer.secondaryRegion, this.employer.secondaryCountry ?? 'US', 
                  this.employer.secondaryCity, this.employer.secondaryStreet, this.employer.secondaryStreetTwo, this.employer.name);
                  
                  const chargify = {promise: (this.fullEmployerObject.employer.billingID && this.fullEmployerObject.employer.billingID.toLowerCase() != 'null') ? this.chargifyStore.updateEmployerSubscription(customer, res.employerID, null, null) : this.chargifyStore.createEmployerSubscription(customer, res.employerID, null, null), name: 'chargifyResponse'}
                  allPromises.push(chargify);
                }

                const hubspot = {promise: this.employerStore.assignHubspotID(this.fullEmployerObject.employer.employerID, this.employer.hubspotId, this.isReviveAdmin ? false : true), name: 'hubspot'}
                allPromises.push(hubspot);

                if(typeof payload.customization.mobileLogo === 'object' && !Array.isArray(payload.customization.mobileLogo) && payload.customization.mobileLogo !== null && payload.customization.enableCustomization) {
                  
                  const mobileLogo = {promise: this.employerStore.uploadLogo(res.employerID, payload.customization.mobileLogo, payload.customization, 'mobileLogo', payload.oldMobileLogo), name: 'uploadMobileLogo'}
                  allPromises.push(mobileLogo);
                } 
  
                this.overlay = false;
              
                const results = await Promise.allSettled(allPromises.map(p => p.promise && p.promise.catch((e) => ({ name: p.name, error: e}) )));
                
                //get the errors
                let errors = [];
                
                results.forEach((res) => {
                  if(res?.value?.name){
                    errors.push(res.value)
                  }
                })

                if(errors.length){

                  let hubspotErrors = errors.filter(e => e.name == 'hubspot');
                  let chargifyError = errors.filter(c => c.name == 'chargifyResponse');

                  let displayOtherErrors = () => {
                      let otherErrors = errors.filter(err => err.name != 'hubspot' && err.name != 'chargifyResponse');
                      
                      let otherErrorsArray = [];
                      otherErrors.forEach((err) => {
                        if(err.name == 'contractDocument' && err.error?.status){
                          otherErrorsArray.push('- ' + err.error?.errors?.file[0]);
                        }else{
                          otherErrorsArray.push('- ' + err.data);
                        }
                      });

                      if(chargifyError.length){
                        Object.keys(chargifyError[0].error).forEach((key) => { 
                          otherErrorsArray.push(`<div> - ${chargifyError[0].error[key][0]}</div>`);
                        });
                      }
                      
                      if(otherErrorsArray.length){
                        return this.$swal({
                          title: "Error",
                          icon: "error",
                          html: `<div>The following errors was encountered: </div> <br /> ` + otherErrorsArray.join('<br /> '),
                          confirmButtonColor: this.$vuetify.theme.themes.light.success,
                          denyButtonColor: this.$vuetify.theme.themes.light.deny,
                          confirmButtonText: "OK",
                        });
                      }else{
                        this.successResponse(payload)
                      }
                  }

                  if(hubspotErrors.length){
                    if(hubspotErrors[0].error.status == 409 && this.isReviveAdmin){
                        let address = `${this.employer.primaryStreet}, ${this.employer.primaryCity}, ${this.employer.primaryRegion}`;
                        return this.$swal({
                          title: "Warning",
                          icon: "warning",
                          html: `<div>${this.employerStore.fieldLabelSettings.groupLabel} named ${this.employer.name} with address of ${address} already exists and this appears to be a duplicate entry within HubSpot. To link this ${this.employerStore.fieldLabelSettings.groupLabel} to their HubSpot record, please add ${this.employerStore.fieldLabelSettings.groupLabel}'s HubSpot ID to their Sponsor DB record.</div>` + `<br/> <div>Please confirm which application ${this.employer.name} should be created in.</div>`,
                          buttons: true,
                          showDenyButton: true,
                          confirmButtonColor: this.$vuetify.theme.themes.light.success,
                          denyButtonColor: this.$vuetify.theme.themes.light.deny,
                          confirmButtonText: "Add to SponsorDB & HubSpot",
                          denyButtonText: "Add only to SponsorDB",
                        }).then((result) => {
                          if (result.isConfirmed) {
                            this.employerStore.assignHubspotID(res.employerID, this.employer.hubspotId, true);
                            displayOtherErrors()
                          }else{
                            displayOtherErrors();
                          }
                        });
                    }else{
                      displayOtherErrors()
                    }
                  }else{
                    displayOtherErrors();
                  }
                }else{
                  this.successResponse(payload)
                }
              }
            })
          } 
        }
      })
      .catch((err) => {
        this.overlay = false;

        if(err.status == 400) {
          this.$swal({
            title: "Error",
            icon: "error",
            html: Object.keys(err.errors).map((key) => { return "<br>" + "- " + err.errors[key][0]; }),
            confirmButtonColor: this.$vuetify.theme.themes.light.success,
            denyButtonColor: this.$vuetify.theme.themes.light.deny,
            confirmButtonText: "Try Again",
          });
        }
      });
    },
    saveEmployer() 
    { 
      this.isSubmit = true; 
    },
    billingAddressToggle(toggle) {
      //toggle the fields. for display purposes only does not get committed to database in case there is a change to the billing address.
      if (toggle) {
        this.employer.secondaryStreet = this.employer.primaryStreet;
        this.employer.secondaryStreetTwo = this.employer.primaryStreetTwo;
        this.employer.secondaryCity = this.employer.primaryCity;
        this.employer.secondaryRegion = this.employer.primaryRegion;
        this.employer.secondaryPostal = this.employer.primaryPostal;
      }
      //Clear the toggle fields.
      else {
        this.employer.secondaryStreet = "";
        this.employer.secondaryStreetTwo = "";
        this.employer.secondaryCity = "";
        this.employer.secondaryRegion = "";
        this.employer.secondaryPostal = "";
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.isDirty) {
      return this.$swal({
        title: "Warning",
        icon: "warning",
        text: "You have unsaved changes. Are you sure you want to leave without saving?",
        showConfirmButton: true,
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonColor: this.$vuetify.theme.themes.light.success,
        denyButtonColor: this.$vuetify.theme.themes.light.deny,
        cancelButtonColor: this.$vuetify.theme.themes.light.deny,
        confirmButtonText: "Save and return",
        denyButtonText: "No",
        cancelButtonText: "Continue without saving"
      }).then((result) => {
        if (result.isConfirmed) {
          this.showSwal = false;
          this.saveEmployer();
        }
        else if(result.isDismissed){ next(); } 
        else { return }
      })
    }else {
      next();
    }
  },
  beforeMount() {
    window.addEventListener("beforeunload", this.preventReload)
  },
  beforeDestroy() {
    window.removeEventListener("beforeunload", this.preventReload);
  },
  mounted() {
    if(this.$route.query.scrollTo){
      document.getElementById(this.$route.query.scrollTo).scrollIntoView();
      this.expandUploadPanel = 0;
    }
    if (this.$route.query.employerID) {
      //get all employers
      this.employerStore.getAllEmployersByActor().then((res) => {
        this.employerList = res;
      });

      this.productStore.getVisitLimitByemployer(this.$route.query.employerID)
      .then((res) => {
        if(res.length){
          this.productsWithVisitLimit = res;
        }
      });

      this.lookupStore.getEmployerTypeList();

      this.employerStore
        .getFullEmployerByID(this.$route.query.employerID)
        .then((res) => {
          
          this.fullEmployerObject = res;
          
          //get employer id
          this.parentEmployerID = this.fullEmployerObject.employer.parentID;
          this.selectedEmployer = this.fullEmployerObject.employer.parentID;
          //get old document url
          this.oldDocumentURL = this.fullEmployerObject.employer.documentURL;
          //parse and populate the products.
          this.getProductsListing();
          // get parent employer products
          this.getProductStockByEmployer()
          //parse object to editable object
          const findExternal = this.fullEmployerObject.employerAttributes.find( (x) => x.attributeType == lookupTypes.attributes.externalID );
          //retrieve employer settings
          this.systemSettings = this.fullEmployerObject.employerAttributes.find(
            (x) => x.attributeType == lookupTypes.settings.system
          );
          
          this.billingSettings = this.fullEmployerObject.employerAttributes.find(
              (x) => x.attributeType == lookupTypes.settings.billing
            );
            if (this.billingSettings && this.billingSettings.attributeValue)
            {
              this.employerBillingSettings = JSON.parse(this.billingSettings.attributeValue);
            }
            else {
              this.employerBillingSettings = {
                billingMethod: null,
                enrolledInTheGroup: null, 
              } 
            }

          this.discountDetails = this.fullEmployerObject.employer;

          this.memberSettings = this.fullEmployerObject.employerAttributes.find(
            (x) => x.attributeType == lookupTypes.settings.member
          );
          this.fieldLabel = this.fullEmployerObject.employerAttributes.find(
            (x) => x.attributeType == lookupTypes.settings.fieldLabel
          );
          this.customization = this.fullEmployerObject.employerAttributes.find(
            (x) => x.attributeType == lookupTypes.settings.customization
          );
          //find and assign hubspot id
          const findHubspotId = this.fullEmployerObject.employerAttributes.find( (x) => x.attributeType == lookupTypes.employer.hubspotID );

          if (findHubspotId){
            this.fullEmployerObject.employer.hubspotId = findHubspotId.attributeValue;
            this.initialHubspotID = findHubspotId.attributeValue;
          }
          // if(this.billingSettings)
          //   this.employerBillingSettings = JSON.parse(this.billingSettings.attributeValue);
          if (findExternal)
            this.fullEmployerObject.employer.externalId = findExternal.attributeValue;

          if(this.fullEmployerObject.employer.billingCustomerID && this.fullEmployerObject.employer.billingID && this.employerBillingSettings.invoiceCollection){
            this.chargifyStore.getEmployerSubscription(this.fullEmployerObject.employer.employerID).then((sub) => {
              this.chargifyUserDetails = sub;
            });
          }
          if(typeof this.fullEmployerObject.employer.groupID == 'undefined' || this.fullEmployerObject.employer.groupID == null){
            this.fullEmployerObject.employer.groupID = 218347
          }
          this.employer = this.fullEmployerObject.employer;
        })
         // setting the pageLoad to true prevents the employer watch from assigning a truthy value to this.isDirty
        .finally(() => this.pageLoad = false);
    }else {
      this.$swal({
        title: "Error",
        text: "Invalid Page",
        icon: "error",
        confirmButtonColor: this.$vuetify.theme.themes.light.success,
        denyButtonColor: this.$vuetify.theme.themes.light.deny,
        confirmButtonText: "Go Back",
        showCancelButton: false,
      }).then((okay) => {
        if (okay) {
          this.$router.push({ name: "group_admin_index" });
        }
      });
    }
  },
  watch: {
    /* using a deep watcher here helps to trigger this callback on a nested employer mutation. 
    Whenever a mutation occurs, the value of isDirty is set to true */
    employer: {
      handler: function(){
        if(!this.pageLoad) {
          this.isDirty = true
        }
      },
      deep: true,
    },
    selectedEmployer(selectedEmployer) {
      
      if (selectedEmployer) {
        this.selected = true;
        this.employerStore.getFullEmployerByID(selectedEmployer).then((res) => {
          this.selectedEmployerDetails = res.employer;

          let billingSettings = res.employerAttributes.find(
            (x) => x.attributeType == lookupTypes.settings.billing
          );
          this.parentEmployerAttribute = JSON.parse(billingSettings.attributeValue);
        })
      //   this.employerStore.getEmployerByID(selectedEmployer).then((res) => { this.selectedEmployerDetails = res });
      //  // get parent employer attributes
      //   this.employerStore.getEmployerSettingsFromHeirarchy(selectedEmployer)
      //   .then((res) => {
      //     let billingSettings = res.find(
      //       (x) => x.attributeType == lookupTypes.settings.billing
      //     );
      //     this.parentEmployerAttribute = JSON.parse(billingSettings.attributeValue);
      //   });
      }
      const found = this.authStore.getUserRoles().find((x) => x == roles.reviveAdmin);
      if(found) {
        this.isReviveAdmin = true;
        this.productStore.getAllProductStock().then((res) => this.allProductStock = res ?? []);
      }
    },
    employerProductList: {
      handler: function(employerProducts){
        this.filteredProductVisitLimits(employerProducts);
      },
      deep: true
    }
  }
};
</script>

<style>
#hasCopay .v-input__prepend-outer{
    width: 70%;
}
input:disabled {
   color: black !important;
}
.v-label--is-disabled{
  color: #00000099 !important;
}
</style>