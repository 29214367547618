<template>
  <v-container>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-card>
      <v-card-title> Add New {{employerStore.fieldLabelSettings.groupLabel}} </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-form v-model="emloyeeIsValid" ref="employeeListForm" lazy-validation>
              <v-row>
                <v-col cols="9">
                  <v-autocomplete :label=" employerList.length > 0 && selectedEmployer != '' ? 'To re-enable '+employerStore.fieldLabelSettings.groupLabel+' selection please click Change '+employerStore.fieldLabelSettings.groupLabel
                    : 'Select Parent ' + employerStore.fieldLabelSettings.groupLabel " v-model.trim="selectedEmployer" :items="employerList" :item-text="(item) => item.name" :item-value="(item) => item.employerID" :disabled="selectedEmployer != ''" 
                    @change="getProductStockByEmployer()" :rules="[(v) => !!v || employerStore.fieldLabelSettings.groupLabel + ' is required']" clearable required></v-autocomplete>
                </v-col>
                <v-col cols="3">
                  <v-btn v-if="selectedEmployer" @click="clearEmployee" color="cancel" class="ma-2" dark> Change {{employerStore.fieldLabelSettings.groupLabel}} </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
        <v-row v-if="selected">
          <v-col cols="12">
            <p class="font-weight-bold primary--text mt-3">
              {{employerStore.fieldLabelSettings.groupLabel}} Demographics:
            </p>
          </v-col>
          <v-col cols="12" class="ml-2">
            <v-form v-model="valid" ref="form" lazy-validation>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field v-model.trim="employer.name" v-maska="{  mask: 'S*', tokens: { S: { pattern: /[0-9a-zA-Z-.£,'&()%# ]/ } } }" :rules="[(v) => !!v || 'Name is required']" label="Name" required></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field v-model.trim="employer.externalId" label="External ID" hint="This is the unique identifer your system uses"></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="4">
                  <v-text-field v-model.trim="employer.email"
                    :rules="[ rules.emailValidation ]"
                    label="Email">
                  </v-text-field>
                </v-col>

                <v-col cols="12" md="4">
                  <label for="phone">Primary Phone</label>
                  <vue-tel-input id="phone" v-model.trim="employer.phone" @input="onPrimaryPhoneObject" />
                  <div v-if="employer.phone">
                    <small v-if="!primaryPhoneObject.valid" class="error--text">Primary Phone number is not valid</small>
                  </div>
                </v-col>

                <v-col cols="12" md="4">
                  <label for="phone2">Secondary Phone</label>
                  <vue-tel-input id="phone2" v-model.trim="employer.phone2" @input="onSecPhoneObject" />
                  <div v-if="employer.phone2">
                    <small v-if="!secPhoneObject.valid" class="error--text">Secondary Phone number is not valid</small>
                  </div>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="5">
                  <v-select v-model.trim="categoryID" :items="employerType" :item-text="(item) => item.name" :item-value="(item) => item.lookupID"
                    :rules="[(v) => !!v || employerStore.fieldLabelSettings.groupLabel + ' Type is required']" :label="employerStore.fieldLabelSettings.groupLabel + ' Type'" required>
                  </v-select>
                </v-col>
                <v-col cols="12" md="5" v-if="isReviveAdmin">
                  <v-select v-model.trim="employer.customerType" :items="customerTypes" :item-text="(item) => item.text" :item-value="(item) => item.value" label="Customer Source"></v-select>
                </v-col>
                <v-col cols="12" md="2" light color="primary" v-if="isReviveAdmin">
                  <v-switch v-model="employer.hasCopay" label="Has Copay?"></v-switch>
                </v-col>
                <v-col cols="12" md="6" v-if="isReviveAdmin">
                  <v-text-field v-model.trim="employer.hubspotId" label="Hubspot ID"></v-text-field>
                </v-col>
                <v-col cols="12" md="6" >
                  <v-text-field :append-icon="isReviveAdmin ? (marker ? 'mdi-pencil' : 'mdi-close-circle') : ''" v-maska="{ mask: '#*', tokens: { '#': { pattern: /[0-9]/ } } }"
                      v-model.trim="employer.groupID" :maxlength="10" :counter="10" :rules="groupIDRules" :readonly="marker" @click:append="marker = !marker" label="Group ID" ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field v-model.trim="employer.primaryStreet" v-maska="{ mask: 'S*', tokens: { S: { pattern: /[a-zA-Z-.£,'&()/%0-9# ]/ } } }" :rules="[(v) => !!v || 'Address is required']" label="Address Line 1" required></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field v-model.trim="employer.primaryStreetTwo" v-maska="{ mask: 'S*', tokens: { S: { pattern: /[a-zA-Z-.£,'&()/%0-9# ]/ } } }" label="Address Line 2" > </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field v-model.trim="employer.primaryCity" v-maska="{ mask: 'S*', tokens: { S: { pattern: /[a-zA-Z-.£,'&()% ]/ } } }" :rules="[(v) => !!v || 'City is required']" label="City" required>
                  </v-text-field>
                </v-col>

              </v-row>
              <v-row class="mb-5">
                <v-col cols="12" md="8">
                  <v-select v-model.trim="employer.primaryRegion" :items="states" :item-text="(item) => item.name" :rules="[(v) => !!v || 'Region is required']" :item-value="(item) => item.abbreviation" label="Select Region" required></v-select>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field v-model.trim="employer.primaryPostal" 
                  :rules="[
                    rules.zipCodeValidation(employer.primaryPostal)
                  ]"
                  v-maska="{
                    mask: '#*',
                    tokens: { '#': { pattern: /[0-9\-]/ } },
                  }"
                  maxlength="10"
                  label="Postal Code" required></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-expansion-panels v-model.trim="shippingExpansionPanel">
                    <v-expansion-panel>
                      <v-expansion-panel-header>
                        <span class="font-weight-bold primary--text mt-3">Shipping Address</span>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content eager>
                          <v-row>
                            <v-col cols="4">
                              <v-switch label="Use The Billing Address" v-model.trim="useBillingAddress" @change="billingAddressToggle($event)"></v-switch>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="12" md="6">
                              <v-text-field v-model.trim="employer.secondaryStreet" v-maska="{ mask: 'S*', tokens: { S: { pattern: /[a-zA-Z-.£,'&()/%0-9# ]/ } } }"
                                label="Address Line 1" :disabled="useBillingAddress" :rules="[(v) => !!v || 'Shipping address is required']" required></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                              <v-text-field v-model.trim="employer.secondaryStreetTwo" v-maska="{ mask: 'S*', tokens: { S: { pattern: /[a-zA-Z-.£,'&()/%0-9# ]/ } } }" label="Address Line 2" :disabled="useBillingAddress"></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="12" md="6">
                              <v-text-field v-model.trim="employer.secondaryCity" v-maska="{ mask: 'S*', tokens: { S: { pattern: /[a-zA-Z-.£,'&()% ]/ } }, }" label="City" :disabled="useBillingAddress" :rules="[(v) => !!v || 'Shipping city is required']" required></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row class="mb-5">
                            <v-col cols="12" md="8">
                              <v-select v-model.trim="employer.secondaryRegion" :items="states" :item-text="(item) => item.name" :item-value="(item) => item.abbreviation" label="Select Region" :disabled="useBillingAddress" :rules="[(v) => !!v || 'Shipping region is required']" required></v-select>
                            </v-col>
                            <v-col cols="12" md="4">
                              <v-text-field v-model.trim="employer.secondaryPostal" 
                              :rules="[
                                rules.zipCodeValidation(employer.secondaryPostal)
                              ]"
                              v-maska="{
                                mask: '#*',
                                tokens: { '#': { pattern: /[0-9\-]/ } },
                              }"
                              maxlength="10"
                              :disabled="useBillingAddress" required></v-text-field>
                            </v-col>
                          </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
          <v-col cols="12" class="ml-2" id="contractUpload">
            <v-expansion-panels v-model.trim="expandUploadPanel">
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <span class="font-weight-bold primary--text mt-3">
                    Document Uploads
                  </span>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-row>
                        <v-col cols="10">
                          <v-file-input v-model.trim="contractDocument" accept=".pdf" placeholder="Upload the contract Document" label="Contract Document" prepend-icon="mdi-paperclip">
                            <template v-slot:selection="{ text }">
                              <v-chip small label color="primary">{{ text }}</v-chip>
                            </template>
                          </v-file-input>
                        </v-col>
                        <v-col cols="2" v-if="false">
                          <v-btn color="success" class="ma-2" :disabled="!contractDocument" @click="downloadFile">Download <v-icon class="ma-2">download</v-icon></v-btn>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
          <v-col cols="12" class="ml-2">
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <span class="font-weight-bold primary--text mt-3">
                    {{employerStore.fieldLabelSettings.secondaryServiceLabel}}: {{ employerProductList.length }} Added
                  </span>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-item-group v-model.trim="addProductList">
                    <v-row>
                        <v-col md="3" v-for="product in filteredProductList" :key="product.skuid" class="m-2">
                          <product-list :isDefault="product.isDefault" :skuid="product.skuid" :name="product.name" :description="product.description" :defaultPrice="product.defaultPrice" :isAdmin="false"></product-list>
                        </v-col>
                        <!-- for admin products -->
                        <v-col md="3" v-for="product in filteredAdminProductList" :key="product.skuid" class="m-2">
                          <product-list :isDefault="product.isDefault" :skuid="product.skuid" :name="product.name" :description="product.description" :defaultPrice="product.defaultPrice" :isAdmin="true"></product-list>
                        </v-col>
                    </v-row>
                  </v-item-group>

                  <v-row v-if="filteredProductList.length || filteredAdminProductList.length">
                    <v-col>
                      <v-btn color="success" class="ma-2" :disabled="addProductList.length < 1" @click="addProducts">Add</v-btn>
                    </v-col>
                  </v-row>

                  <v-row v-if="filteredProductList.length || filteredAdminProductList.length">
                    <v-col cols="12">
                      <p class="font-weight-bold primary--text mt-5">
                        {{employerStore.fieldLabelSettings.secondaryServiceLabel}} to Add:
                      </p>
                    </v-col>
                  </v-row>
                  <v-row> 
                    <v-col cols="12">
                      <v-data-table :headers="employerProductHeaders" :items="employerProductList" hide-default-footer>
                        <template v-slot:[`item.actions`]="{ item }">
                          <v-icon small class="mr-2" @click="removeEmployerProduct(item)">delete</v-icon>
                        </template>
                        <template v-slot:[`item.isDefault`]="{ item }">
                          <v-switch v-model.trim="item.isDefault" @change="handleChange($event, item.skuid, item.isDefault)"></v-switch>
                        </template>
                        <template v-slot:no-data>
                          No {{employerStore.fieldLabelSettings.serviceLabel}} added. Please add a {{employerStore.fieldLabelSettings.serviceLabel}} to this {{employerStore.fieldLabelSettings.groupLabel}}.
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
          <v-col cols="12" class="ml-2" v-if="filteredProductsWithVisitLimit.length">
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <span class="font-weight-bold primary--text mt-3">
                    {{employerStore.fieldLabelSettings.secondaryServiceLabel}} Visit Limits
                  </span>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-card>
                      <v-list-item-group>
                        <v-list-item v-for="(product, i) in filteredProductsWithVisitLimit" :key="i" :value="product">
                            <v-list-item-content>
                              <v-list-item-title>{{ product.name }}</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-text-field class="pe-4" v-maska="{ mask: '#*', tokens: { '#': { pattern: /[0-9]/ } },}" v-model="product.visitLimit" label="Visit Limit"></v-text-field>
                            </v-list-item-action>
                            <v-list-item-action>
                              <v-switch color="primary" v-model="product.enableFeeForService">
                                <template v-slot:label>
                                  <div class="ml-2">
                                    Enable Fee for Visit Limit?
                                  </div>
                                </template>
                              </v-switch>
                            </v-list-item-action>
                            <v-list-item-action>
                              <v-text-field v-model="product.serviceFee" v-maska="{ mask: '#*', tokens: { '#': { pattern: /[0-9\.]/ } },}" :disabled="!product.enableFeeForService" label="Fee for Visit Limit"></v-text-field>
                            </v-list-item-action>
                        </v-list-item>
                      </v-list-item-group>
                  </v-card>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
          <v-col cols="12" class="ml-2">
            <employer-settings @employerSettings="handleSettings" :isSubmitted="isSubmit" :isAdmin="isReviveAdmin" 
                  :parentAttributes="parentEmployerAttribute" :employerProducts="employerProductList"
                  :employerID="employerID" :customerType="employer.customerType" :isNewEmployer="true" 
                  :parentEmpID="getSelectedEmployerID"></employer-settings>
          </v-col>
          <v-col cols="12" class="d-flex justify-center">
            <v-btn color="success" class="ma-2" @click="saveEmployer">Save</v-btn>
            <v-btn color="cancel" dark class="ma-2" @click="$router.push({ name: 'group_admin_index' })">Cancel</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import states from "../../../state.json";
import { useAuthStore } from "../../stores/auth";
import { roles } from "../../data/definitions/roles";
import { useLookupStore } from "../../stores/lookup";
import ProductList from "../ProductListComponent.vue";
import EmployerSettings from "./SettingsComponent.vue";
import { useProductStore } from "../../stores/product";
import { useEmployerStore } from "../../stores/employer";
import { lookupTypes } from "../../data/definitions/lookupTypes";
import {
  convertToCustomerAttribute,
  convertToEmployerObject,
  convertToAssignLimitType,
  convertToSettingsPayload,
  download,
  handleRedirectAfterSavingEmployer,
  customerTypes,
  employerPricingStructureTypes
} from "../../commons/employer";
import { useChargifyStore } from "../../stores/chargify";
import { randomString } from "../../commons/user";
import { isValidEmail } from "../../commons/formValidators";

export default {
  name: "NewEmployerComponent",
  components: { EmployerSettings, ProductList },
  setup() {
    const employerStore = useEmployerStore();
    const lookupStore = useLookupStore();
    const productStore = useProductStore();
    const authStore = useAuthStore();
    const chargifyStore = useChargifyStore();

    return { employerStore, productStore, lookupStore, authStore, states, chargifyStore, customerTypes };
  },
  data() {
    return {
      expandUploadPanel: 1,
      overlay: false,
      primaryPhoneObject: {
        valid: false,
      },
      secPhoneObject: {
        valid: false,
      },
      contractDocument: null,
      isReviveAdmin: false,
      isSubmit: false,
      validPhoneInput: true,
      valid: true,
      emloyeeIsValid: true,
      shippingExpansionPanel: 0,
      employer:{
        name: "",
        externalId: "",
        hubspotId: "",
        phone: "",
        phone2: "",
        primaryStreet: "",
        primaryStreetTwo: "",
        primaryCity: "",
        primaryRegion: "",
        primaryPostal: "",
        primaryCountry: "US",
        secondaryStreet: "",
        secondaryStreetTwo: "",
        secondaryCity: "",
        secondaryRegion: "",
        secondaryPostal: "",
        secondaryCountry: "US",
        groupID: "218347",
        customerType: "",
        email:""
      },
      marker: true,
      rules: {
        zipCodeValidation(zipCode){
          if(/^\d{5}?(([-]|\s*)(\d{4}))?$/.test(zipCode))
            return true;
          return "Postal code must be valid";
        },
        zipCodeValidationPostal(zipCode){
          if(/^\d{5}?(([-]|\s*)(\d{4}))?$/.test(zipCode))
            return true;
          return "Shipping Postal code must be valid";
        },
        emailValidation: [
          (emailAddress) => !emailAddress || isValidEmail(emailAddress) || "E-mail must be valid"
        ],
      },
      groupIDRules: [(v) => !!v || "Group ID is required", (v) => (v && v.length <= 10) || "Group ID must not exceed 10 characters"],
      selected: false,
      selectedEmployer: "",
      categoryID: "",
      depType: "",
      productList: [],
      productListFromApi: [],
      addProductList: [],
      employerProductList: [],
      useBillingAddress: false,
      employerProductHeaders: [ { text: "Name", value: "name" }, { text: "Description", value: "description" }, { text: "Default", value: "isDefault" }, { text: "Remove", value: "actions", sortable: false } ],
      allProductStock: [],
      isDirty: false,
      showSwal: true,
      dontWarnDocument: false,
      parentEmployerAttribute: [],
      hasCopay: false,
      employerList: [],
      productsWithVisitLimit: [],
      filteredProductsWithVisitLimit: []
    };
  },
  computed: {
    getSelectedEmployerID(){
      return this.selectedEmployer;
    },
    employerType: {
      get() { return this.lookupStore.employerType; }
    },
    filteredProductList: {
      get() {
        if (this.employerProductList.length > 0) {
          let newList = [];
          //find products already added to employer and remove from available list.
          this.productList.forEach((product) => {
            const found = this.employerProductList.find( (x) => x.skuid == product.skuid );
            if (!found) { newList.push(product); }
          });
          return newList;
        }
        return this.productList;
      },
    },
    filteredAdminProductList: {
      get() {
        let filteredList = [];
        this.allProductStock.forEach((product) => {
          const found = this.productListFromApi.find( (x) => x.skuid == product.skuid );
          const foundProduct = this.productList.find( (x) => x.skuid == product.skuid );
          if (!found && !foundProduct) { filteredList.push(product); }
        });
        if (this.employerProductList.length > 0) {
          let newList = [];
          filteredList.forEach((product) => {
            const found = this.employerProductList.find( (x) => x.skuid == product.skuid );
            if (!found) { 
              product.isDefault = false;
              newList.push(product); 
            }
          });
          return newList;
        }else{
          return filteredList;
        }
      },
    },
  },
  methods: {
    filteredProductVisitLimits(filteredProductList) {
      if (this.productsWithVisitLimit.length && filteredProductList.length) {
        let productList = this.productsWithVisitLimit.filter(product => filteredProductList.some(x => x.skuid === product.skuid));
        
        this.filteredProductsWithVisitLimit = productList.filter((product, index, array) => array.findIndex(item => item.name === product.name) === index);

        return;
      }
      this.filteredProductsWithVisitLimit = [];
    },
    handleChange(e,id,value){
      this.employerProductList.forEach( (ep) => {
        if(ep.skuid.toLowerCase() === id.toLowerCase())
          ep.isDefault = value;
        else
          ep.isDefault = false;
      });
      
      let checkForDefaultProduct = this.employerProductList.find(x => x.isDefault)
      
      if (!checkForDefaultProduct) {
        return this.$swal({
          title: "Error",
          icon: "error",
          text: "Default product is not selected.",
          confirmButtonColor: this.$vuetify.theme.themes.light.success,
        });
      }
    },
    downloadFile(){
      try {
        download(this.contractDocument, this.employerStore.fieldLabelSettings.groupLabel + ' contract.pdf')
      } catch (error) {
        return this.$swal({
            title: 'Error',
            text: 'An error occurred: ' + error,
            icon: 'error',
            confirmButtonText: 'Try Again',
            confirmButtonColor: this.$vuetify.theme.themes.light.success
          });
      }
    },
    preventReload(event) {
      // the beforeunload event functions based on the value of isDirty. if false, event would run but if true, user would receive a prompt message when reloading page 
      if (!this.isDirty) return
      event.preventDefault();
      event.returnValue = "";
    },
    getProductStockByEmployer () {
      if (this.selectedEmployer != '') {
        this.productStore
          .getProductStockByEmployer(this.selectedEmployer)
          .then((res) => {
            this.productListFromApi = res.data ?? []
            this.productList = res.data ?? [];
            return;
          });
      }
      this.productListFromApi = [];
      this.productList = [];
    },
    addProducts() {
      this.addProductList.settings = { enableApps: null, selectedApps: [] };
      if(this.employer.customerType == 'manifest'){
        this.addProductList.settings.enableApps = 'No';
      }
      else{
        this.addProductList.settings.enableApps = 'Yes';
        this.addProductList.settings.selectedApps.push(lookupTypes.partners.reviveApp);
      }
      this.employerProductList.push(this.addProductList);
      this.addProductList = [];
    },
    removeEmployerProduct(item) {
      this.employerProductList = [ ...this.employerProductList.filter((x) => x != item) ];
    },
    onPrimaryPhoneObject(formattedNumber, phoneObject) {
      this.primaryPhoneObject = phoneObject;
    },
    onSecPhoneObject(formattedNumber, phoneObject) {
      this.secPhoneObject = phoneObject;
    },
    successResponse(payload, employerID){
      if(this.showSwal) {
        this.$swal({
          title: "Saved",
          icon: "success",
          confirmButtonColor: this.$vuetify.theme.themes.light.success,
          denyButtonColor: this.$vuetify.theme.themes.light.deny,
          confirmButtonText: "Continue",
          denyButtonText: "Add Another",
          buttons: true,
          showDenyButton: true,
        }).then((result) => {
          if (result.isConfirmed) { this.$router.push(handleRedirectAfterSavingEmployer(payload.billing.invoiceCollection, payload.billing.billingMethod, employerID));} 
          if (result.isDenied) { this.$router.go(); }
        });
      }else {
        this.showSwal = true;
        this.$router.push(handleRedirectAfterSavingEmployer(payload.billing.invoiceCollection, payload.billing.billingMethod, employerID));
      }
    },
    clearEmployee() {
      this.$swal({
        title: "Warning",
        icon: "warning",
        text: "Warning, this will clear any " + this.employerStore.fieldLabelSettings.serviceLabel +" you have added to this "+this.employerStore.fieldLabelSettings.groupLabel+", are you sure you wish to continue.",
        buttons: true,
        showDenyButton: true,
        confirmButtonColor: this.$vuetify.theme.themes.light.success,
        denyButtonColor: this.$vuetify.theme.themes.light.deny,
        confirmButtonText: "Continue",
        denyButtonText: "Cancel",
      }).then((result) => {
        if (result.isConfirmed) {
          this.productList = [];
          this.addProductList = [];
          this.employerProductList = [];
          this.selectedEmployer = "";
        }
      });
    },
    async handleSettings(payload){
      this.isSubmit = false;

      let phoneValidation = !this.primaryPhoneObject.valid
        ? "- Primary Phone Number is Required"
        : "";
      if (phoneValidation) {
        this.employer.phone = "+1";
      }
      let secondaryPhoneValidation = this.employer.phone2 && !this.secPhoneObject.valid ? "<br/> - Secondary Phone Number is Invalid" : "";
      let swal = (validation) => {
        let formValidate = validation == this.$refs.form._data.inputs ? true : false;
        let phoneNum = formValidate == true ? phoneValidation : "";
        let settingsFormObject = payload.valid == true ? "" : payload.validationMessages;
        let settingsPhoneNumValidation = !payload.phoneObject.valid && payload.customization.phoneNumber ? "<br/> - Customization Phone Number is Invalid" : "";
        
        let newValidationObject;
        if(settingsFormObject) {
          newValidationObject = Object.assign(settingsFormObject, validation);
        }else {
          newValidationObject = validation;
        }

        this.$swal({
          title: "Error",
          icon: "error",
          html: (
            phoneNum + secondaryPhoneValidation + settingsPhoneNumValidation +
            Object.keys(newValidationObject).map((key) => {
              if (newValidationObject[key].validations) {
                if (newValidationObject[key].validations.length > 0) {
                  return "<br>" + "- " + newValidationObject[key].validations[0];
                }
              }
            })
          ).replace(/,/g, ""),
          confirmButtonColor: this.$vuetify.theme.themes.light.success,
          denyButtonColor: this.$vuetify.theme.themes.light.deny,
          confirmButtonText: "Try Again",
        });
      };

      if (!this.$refs.employeeListForm.validate()) {
        return swal(this.$refs.employeeListForm.$children);
      }

      if (!this.$refs.form.validate() || !this.primaryPhoneObject.valid || !payload.valid || !payload.phoneObject.valid && payload.customization.phoneNumber || this.employer.phone2 != '' && !this.secPhoneObject.valid) {

        return swal(this.$refs.form._data.inputs);
      }

      if (!this.employerProductList.length > 0) {
        this.$swal({
          title: "Error",
          icon: "error",
          text: "Choosing a " + this.employerStore.fieldLabelSettings.serviceLabel +" is required to create an "+this.employerStore.fieldLabelSettings.groupLabel,
          confirmButtonColor: this.$vuetify.theme.themes.light.success,
        });
        return;
      }
      //if we made it this far check only secondary phone number again. 
      if (secondaryPhoneValidation.length > 1){
        this.$swal({
          title: "Error",
          icon: "error",
          html: secondaryPhoneValidation,
          confirmButtonColor: this.$vuetify.theme.themes.light.success,
          denyButtonColor: this.$vuetify.theme.themes.light.deny,
        });
        return;
      }

      if(!this.contractDocument && payload.billing.billingMethod == 'individualBilling' && !this.dontWarnDocument) {
        return this.$swal({
          title: "Warning",
          icon: "warning",
          text: `You haven't uploaded a contract. Please upload a contract before you can add ${this.employerStore.fieldLabelSettings.secondarySubscriberLabel}.`,
          buttons: true,
          showDenyButton: true,
          confirmButtonColor: this.$vuetify.theme.themes.light.success,
          denyButtonColor: this.$vuetify.theme.themes.light.deny,
          confirmButtonText: "Ok",
          denyButtonText: "Proceed Anyway",
        }).then((result) => {
          if (result.isConfirmed) {
            document.getElementById('contractUpload').scrollIntoView();
            this.expandUploadPanel = 0;
          }
          else {
            this.dontWarnDocument = true;
            this.isSubmit = true;
          }
        });
      }

      if (this.contractDocument && this.contractDocument.type !== 'application/pdf')
      {
        return this.$swal({
          title: "Error",
          icon: "error",
          html: "The document uploaded was not the correct type. Please upload a PDF and try again",
          confirmButtonColor: this.$vuetify.theme.themes.light.success,
          denyButtonColor: this.$vuetify.theme.themes.light.deny,
          confirmButtonText: "Try Again",
        });
      }

      let checkForDefaultProduct = this.employerProductList.find(x => x.isDefault)
      
      if (!checkForDefaultProduct) {
        this.$swal({
          title: "Error",
          icon: "error",
          text: "Default product is not selected.",
          confirmButtonColor: this.$vuetify.theme.themes.light.success,
        });
        return;
      }
      
      //get employer pricing
      let pricingRequest = null;
      let employerBillingObj = JSON.parse(JSON.stringify(payload.billing.pricing));

      if(employerBillingObj != null && !employerBillingObj.isFromParent){
          if(employerBillingObj.pricingStructure === '1'){
            pricingRequest = {
              pricings: [...employerBillingObj.pricing],
              type: employerPricingStructureTypes.pmpm,
              pricingDetails: {
                hasFlatRate: employerBillingObj.hasFlatRate,
                flatRateFee: employerBillingObj.flatRateFee
              }
            };
          }
          else if(employerBillingObj.pricingStructure === '2'){
            pricingRequest = {
              pricings: [...employerBillingObj.pricing],
              type: employerPricingStructureTypes.blended,
              pricingDetails: {
                hasFlatRate: employerBillingObj.hasFlatRate,
                flatRateFee: employerBillingObj.flatRateFee
              }
            };
          }
          else if(employerBillingObj.pricingStructure === '3'){
            if(employerBillingObj.tierStructure === '1'){
              pricingRequest = {
                pricings: [...employerBillingObj.pricing],
                type: employerPricingStructureTypes.tiered3,
                pricingDetails: {
                  hasFlatRate: employerBillingObj.hasFlatRate,
                  flatRateFee: employerBillingObj.flatRateFee
                }
              };
            }
            else if(employerBillingObj.tierStructure === '2'){
              pricingRequest = {
                pricings: [...employerBillingObj.pricing],
                type: employerPricingStructureTypes.tiered4,
                pricingDetails: {
                  hasFlatRate: employerBillingObj.hasFlatRate,
                  flatRateFee: employerBillingObj.flatRateFee
                }
              };
            }
          } 
      }
      //Set new billing payload
      let newBillPayload = {
        billingMethod: payload.billing?.billingMethod,
        enrolledInTheGroup: payload.billing?.enrolledInTheGroup,
        invoiceCollection: payload.billing?.invoiceCollection,
        netTerm: payload.billing?.netTerm
      };

      payload.billing = newBillPayload;

      if(newBillPayload.enrolledInTheGroup === false && (pricingRequest != null && pricingRequest.pricingDetails != null 
          && pricingRequest.pricingDetails.hasFlatRate == 1) ){
            this.$swal({
              title: "Warning",
              icon: "warning",
              text: 'You have entered a billing flat rate for the group, but "Can Enroll Members" is set to no. The Flat Rate fee will not be billed for this group.',
              buttons: true,
              showDenyButton: true,
              confirmButtonColor: this.$vuetify.theme.themes.light.success,
              denyButtonColor: this.$vuetify.theme.themes.light.deny,
              confirmButtonText: "Update Setting",
              denyButtonText: "Proceed Anyway",
            }).then((result) => {
                if (!result.isConfirmed) {
                  this.finalizeSavingEmployer(payload, pricingRequest);
                }
            });       
      } else{
        this.finalizeSavingEmployer(payload, pricingRequest);
      }
    },

    finalizeSavingEmployer(payload, pricingRequest){
      //isDirty has to be set to false else the prompt message would show up when the route is about to change 
      this.isDirty = false;
      //set overlay to true
      this.overlay = true;

      let employer = convertToEmployerObject(this.employer.name, this.employer.externalId, this.employer.hubspotId, this.employer.phone, 
                          this.employer.phone2, this.employer.primaryStreet, this.employer.primaryStreetTwo, this.employer.primaryCity, 
                          this.employer.primaryRegion, this.employer.primaryPostal, this.employer.primaryCountry,
                          this.employer.secondaryStreet, this.employer.secondaryStreetTwo, this.employer.secondaryCity, this.employer.secondaryRegion, 
                          this.employer.secondaryPostal, this.employer.secondaryCountry, this.categoryID, this.selectedEmployer, this.employer.groupID, 
                          payload.discount.discountPercentage, payload.discount.numberOfMonths, this.employer.customerType, this.employer.hasCopay, this.employer.email);
      
      this.employerStore.createEmployer(employer).then((res) => {
        if(res && res.employerID) {
          //set pricing request employer ID
          if(pricingRequest != null)
            pricingRequest.employerID = res.employerID;
          
              
          let allPromises = [];
            
          let settingsArray = convertToSettingsPayload(res.employerID, payload, this.employer.hubspotId);
          
          if (settingsArray.length > 0)
          {               
            //first assign settings
            this.employerStore.assignSettings(res.employerID, settingsArray).then(async (settingsResult) => {
              //check if this result is a success. 
              if(settingsResult.status == 200){

                if(typeof payload.customization.mainLogo === 'object' && !Array.isArray(payload.customization.mainLogo) && payload.customization.mainLogo !== null) {

                  const upload = {promise: this.employerStore.uploadLogo(res.employerID, payload.customization.mainLogo, payload.customization, 'mainLogo', payload.oldMainLogo), name: 'uploadMainLogo'};
                  allPromises.push(upload)
                }
                
                if(this.employerProductList.length > 0){
                  this.employerProductList.forEach((prod) => { 
                    let partners = [];
                    if(prod.settings?.selectedApps?.length > 0){
                      partners = prod.settings.selectedApps.map(partnerID => { return {partnerID: partnerID }; });
                    }
                    let addedproduct = {promise: this.employerStore.assignProduct( res.employerID, prod.skuid, prod.defaultPrice, prod.isDefault, partners), name: `addedProduct${prod.skuid}`}
                    addedproduct.promise.then((res) => {
                      if(prod.settings)  {
                        let skuSetting = {
                          attributeID: prod.settings.attributeID,
                          employerSKUID: res.employerSKUID,
                          attributeType: lookupTypes.settings.enableApps,
                          attributeValue: prod.settings.enableApps,
                          language: 'en-us'
                        };
                        if(skuSetting.attributeValue == null){
                          skuSetting.attributeValue = '';
                        }
                        const assignSkuSettingsPromise = {promise: this.employerStore.assignSkuSettings([skuSetting]), name: `addedProduct${prod.skuid}`};
                        allPromises.push(assignSkuSettingsPromise);
                      }
                    })
                    allPromises.push(addedproduct);
                  })
                }
                
                if(typeof this.contractDocument === 'object' && !Array.isArray(this.contractDocument) && this.contractDocument !== null){
                  
                  const contractUpload = {promise: this.employerStore.uploadContract(res.employerID, employer, this.contractDocument), name: 'contractDocument'};
                  allPromises.push(contractUpload);
                }

                if(this.employer.externalId){
                  
                  const assignID = {promise: this.employerStore.assignUniqueID( res.employerID, this.employer.externalId), name: 'assignUniqueID'};
                  allPromises.push(assignID);
                }

                if(this.filteredProductsWithVisitLimit.length > 0){
                  const assignVisit = {promise: this.employerStore.assignVisitLimits(res.employerID, convertToAssignLimitType(res.employerID, this.filteredProductsWithVisitLimit)), name: 'assignVisitLimits'}
                  allPromises.push(assignVisit);
                }

                if(pricingRequest != null){
                  const pricingUpdate = {promise: this.employerStore.saveEmployerPricingStructure(pricingRequest), name: 'pricingSaveRequest'}
                  allPromises.push(pricingUpdate);
                }

                //check if remitsettings is enabled, then push to the array
                if(payload.billing.invoiceCollection == 'remittance'){
                  let customer = convertToCustomerAttribute(payload.user.first_name, payload.user.last_name, payload.user.email, 
                    this.employer.secondaryPostal,  this.employer.phone, this.employer.secondaryRegion, this.employer.secondaryCountry ?? 'US', 
                    this.employer.secondaryCity, this.employer.secondaryStreet, this.employer.secondaryStreetTwo, this.employer.name);
                    
                  const chargifyResponse = {promise: this.chargifyStore.createEmployerSubscription(customer, res.employerID, null, null), name: 'chargifyResponse'};
                  allPromises.push(chargifyResponse);
                }

                const hubspotResponse = {promise: this.employerStore.assignHubspotID(res.employerID, this.employer.hubspotId, this.isReviveAdmin ? false : true), name: 'hubspot'};
                allPromises.push(hubspotResponse);

                if(typeof payload.customization.mobileLogo === 'object' && !Array.isArray(payload.customization.mobileLogo) && payload.customization.mobileLogo !== null) {
  
                  let mobileLogoPromise = {promise: this.employerStore.uploadLogo(res.employerID, payload.customization.mobileLogo, payload.customization, 'mobileLogo', payload.oldMobileLogo), name: 'uploadMobileLogo'};
                  allPromises.push(mobileLogoPromise)
                }

                const results = await Promise.allSettled(allPromises.map(p => p.promise));
                this.overlay = false;

                //get the errors
                let errors = [];

                results.forEach((res) => {
                  if(res?.value?.name){
                    errors.push(res.value)
                  }
                })

                if(errors.length){

                  let hubspotErrors = errors.filter(e => e.name == 'hubspot');
                  let chargifyError = errors.filter(c => c.name == 'chargifyResponse');

                  let displayOtherErrors = () => {
                      let otherErrors = errors.filter(err => err.name != 'hubspot' && err.name != 'chargifyResponse');
                      
                      let otherErrorsArray = [];
                      otherErrors.forEach((err) => {
                        if(err.name == 'contractDocument' && err.error?.status){
                          otherErrorsArray.push('- ' + err.error?.errors?.file[0]);
                        }else{
                          otherErrorsArray.push('- ' + err.data);
                        }
                      });

                      if(chargifyError.length){
                        Object.keys(chargifyError[0].error).forEach((key) => { 
                          otherErrorsArray.push(`<div> - ${chargifyError[0].error[key][0]}</div>`);
                        });
                      }
                      
                      if(otherErrorsArray.length){
                        return this.$swal({
                          title: "Error",
                          icon: "error",
                          html: `<div>The following errors was encountered: </div> <br /> ` + otherErrorsArray.join('<br /> ') + `<br /> <div>Please make the neccessary correction from the Update Employer page</div>`,
                          confirmButtonColor: this.$vuetify.theme.themes.light.success,
                          denyButtonColor: this.$vuetify.theme.themes.light.deny,
                          confirmButtonText: "OK",
                        }).then((result) => {
                          if(result.isConfirmed){
                            this.$router.push({ name: 'group_admin_edit', query: { employerID: res.employerID } })
                          }
                        });
    
                      }else{
                        this.successResponse(payload, res.employerID)
                      }
                  }

                  if(hubspotErrors.length){
                    if(hubspotErrors[0].error.status == 409 && this.isReviveAdmin){
                        let address = `${this.employer.primaryStreet}, ${this.employer.primaryCity}, ${this.employer.primaryRegion}`;
                        return this.$swal({
                          title: "Warning",
                          icon: "warning",
                          html: `<div>${this.employerStore.fieldLabelSettings.groupLabel} named ${this.employer.name} with address of ${address} already exists and this appears to be a duplicate entry within HubSpot. To link this ${this.employerStore.fieldLabelSettings.groupLabel} to their HubSpot record, please add ${this.employerStore.fieldLabelSettings.groupLabel}'s HubSpot ID to their Sponsor DB record.</div>` + `<br/> <div>Please confirm which application ${this.employer.name} should be created in.</div>`,
                          buttons: true,
                          showDenyButton: true,
                          confirmButtonColor: this.$vuetify.theme.themes.light.success,
                          denyButtonColor: this.$vuetify.theme.themes.light.deny,
                          confirmButtonText: "Add to SponsorDB & HubSpot",
                          denyButtonText: "Add only to SponsorDB",
                        }).then((result) => {
                          if (result.isConfirmed) {
                            this.employerStore.assignHubspotID(res.employerID, this.employer.hubspotId, true);
                            displayOtherErrors()
                          }else{
                            displayOtherErrors();
                          }
                        });
                    }else{
                      displayOtherErrors()
                    }
                  }else{
                    displayOtherErrors();
                  }
                  

                } else{
                  this.successResponse(payload, res.employerID);
                  if(!this.employerSettings.system.enrollmentCode){
                    this.employerSettings.system.enrollmentCode = randomString(10);
                }}
                this.enrollmentCode = this.employerSettings.system.enrollmentCode
              }
                //const validResults = results.filter(result => !(result instanceof Error));
                //TODO: leave the todo in here. Typescript has a better way to implement when vue 3 is implemented. 
                
                //instructions 
                //Loop through results and find any where item.value.errors is has value. Get the index of this. 
                //Use that index to get the name of the method you called so you can decide what to do. Since hubspot is the only one that would have you 
                // allow the revive admin to push through if it is the only error then display the do you want to create in hubspot anyway message we have. 
                // more than that for errors you want to show the errors and redirect to the edit page to have them resolve them. 
                // no errors do the billing collection redirect as normal flow and show success. 
            });
          }
        }
      })
      .catch((err) => {
          this.overlay = false;
        if(err.status == 400) {
          this.$swal({
            title: "Error",
            icon: "error",
            html: Object.keys(err.errors).map((key) => { return "<br>" + "- " + err.errors[key][0]; }),
            confirmButtonColor: this.$vuetify.theme.themes.light.success,
            confirmButtonText: "Try Again",
          });
        }
      });
    },
    saveEmployer() {
      this.isSubmit = true;
    },
    billingAddressToggle(toggle) {
      //toggle the fields. for display purposes only does not get committed to database in case there is a change to the billing address.
      if (toggle) {
        this.employer.secondaryStreet = this.employer.primaryStreet;
        this.employer.secondaryStreetTwo = this.employer.primaryStreetTwo;
        this.employer.secondaryCity = this.employer.primaryCity;
        this.employer.secondaryRegion = this.employer.primaryRegion;
        this.employer.secondaryPostal = this.employer.primaryPostal;
        this.employer.secondaryCountry = this.employer.primaryCountry;
      }
      //Clear the toggle fields.
      else {
        this.employer.secondaryStreet = "";
        this.employer.secondaryStreetTwo = "";
        this.employer.secondaryCity = "";
        this.employer.secondaryRegion = "";
        this.employer.secondaryPostal = "";
        this.employer.secondaryCountry = "US";
      }
    },
  },
  watch: {
    // using a deep watcher here helps to trigger this callback on a nested employer mutation. Whenever a mutation occurs, the value of isDirty is set to true
    employer: {
      handler: function(){
        this.isDirty = true
      },
      deep: true,
    },
    selectedEmployer(selectedEmployer) {
      if (selectedEmployer) { 
        this.selected = true; 
        // get parent employer attributes
        // this.employerStore.getEmployerSettingsFromHeirarchy(selectedEmployer)
        // .then((res) => {
        //   let billingSettings = res.find(
        //     (x) => x.attributeType == lookupTypes.settings.billing
        //   );
        //   this.parentEmployerAttribute = JSON.parse(billingSettings.attributeValue);
        // });

        this.employerStore.getFullEmployerByID(selectedEmployer).then((res) => {

          let billingSettings = res.employerAttributes.find(
            (x) => x.attributeType == lookupTypes.settings.billing
          );
          this.parentEmployerAttribute = JSON.parse(billingSettings.attributeValue);
        })
      }
      const found = this.authStore.getUserRoles().find((x) => x == roles.reviveAdmin);
      
      if(found) { this.isReviveAdmin = true;
        this.productStore.getAllProductStock().then(res => this.allProductStock = res ?? []);
      }
    },
    employerProductList: {
      handler: function(employerProducts){
        this.filteredProductVisitLimits(employerProducts);
      },
      deep: true
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.isDirty) {
      return this.$swal({
        title: "Warning",
        icon: "warning",
        text: "You have unsaved changes. Are you sure you want to leave without saving?",
        showConfirmButton: true,
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonColor: this.$vuetify.theme.themes.light.success,
        denyButtonColor: this.$vuetify.theme.themes.light.deny,
        cancelButtonColor: this.$vuetify.theme.themes.light.deny,
        confirmButtonText: "Save and return",
        denyButtonText: "No",
        cancelButtonText: "Continue without saving"
      }).then((result) => {
        if (result.isConfirmed) {
          this.showSwal = false;
          this.saveEmployer();
        }else if(result.isDismissed){ next(); } 
        else { return }
      })
    }else {
      next();
    }
  },
  beforeMount() {
    window.addEventListener("beforeunload", this.preventReload)
  },
  beforeDestroy() {
    window.removeEventListener("beforeunload", this.preventReload);
  }, 
  mounted() {
    //get all employers
    this.employerStore.getAllEmployersByActor().then((res) => {
      this.employerList = res;
    });

    this.productStore.getVisitLimitByemployer(null)
    .then((res) => {
      if(res.length){
        this.productsWithVisitLimit = res;
      }
    });

    this.lookupStore.getEmployerTypeList();
  },
};
</script>
